<template>
  <div class="admin-page">
    <div v-if="textEditorWindow" class="text-editor">
      <text-editor :text="fullText" @save-text="saveText" />
    </div>
    <div class="admin-menu">
      <p :class="{ selected: category === 'add' }" @click="setCategory('add')" class="menu-item">Přidat</p>
      <p :class="{ selected: category === 'remove' }" @click="setCategory('remove')" class="menu-item">Smazat</p>
      <p :class="{ selected: category === 'edit' }" @click="setCategory('edit')" class="menu-item">Upravit</p>
      <p :class="{ selected: category === 'list' }" @click="setCategory('list')" class="menu-item">Seznam</p>
    </div>
    <!-- ADD FORM -->
    <form v-if="category === 'add'">
      <input placeholder="Název" class="name" v-model="name" />
      <textarea placeholder="Popis" class="text" v-model="text"></textarea>
      <select class="remove-select" v-model="isText">
        <option :value="0">Media</option>
        <option :value="1">Text</option>
      </select>

      <div @click.prevent="textEditorWindow = true" v-if="isText" class="text-button">
        <button>Upravit text</button>
      </div>
      <div v-else class="files">
        <div class="media-file">
          <label for="media">MEDIA</label>
          <input ref="mediaFile" @change="e => previewMediaFile(e)" type="file" class="file" id="media" />
        </div>
        <div v-if="isAudioFile" class="media-file">
          <label for="audio-picture">FOTO K AUDIU</label>
          <input ref="audioPicture" @change="e => previewAudioFoto(e)" type="file" class="file" id="audio-picture" />
        </div>
        <label for="show-links">links: </label>
        <input @change="e => (showLinks = e.target.checked)" id="show-links" type="checkbox" />
        <div class="links" v-if="showLinks">
          <label for="youtube-link">YOUTUBE link:</label>
          <input v-model="youtubeLink" id="youtube-link" placeholder="https://" />
          <label for="spotify-link">SPOTIFY link:</label>
          <input v-model="spotifyLink" id="spotify-link" placeholder="https://" />
          <label for="simplecast-link">SIMPLECAST link:</label>
          <input v-model="simplecastLink" id="simplecast-link" placeholder="https://" />
        </div>
      </div>
      <div class="submit" @click.prevent="!loading ? uploadArticle() : ''">
        <p v-if="!loading">NAHRÁT</p>
        <div v-else class="spin-icon"><unicon name="spinner" /></div>
      </div>
      <div v-if="loading" class="upload-progress">
        <div :style="{ width: `${loaded / (total / 100)}%` }" class="inner-bar"></div>
      </div>
    </form>
    <div v-if="category === 'add'" class="article-full">
      <h1><span></span>{{ name ? name : '' }}</h1>
      <img v-if="mediaFile && mediaFile.type.split('/')[0] === 'image'" class="article-picture" :src="mediaFileURL" />
      <p v-if="mediaFile && mediaFile.type.split('/')[0] === 'image'" class="picture-likes">
        <span>LIKES: </span>{{ Math.ceil(Math.random() * 1000000) }}
      </p>
      <p class="type">
        {{ mediaFile ? mediaFile.type : '/' }}
      </p>
      <p class="generated-link">
        <span v-for="(textObject, index) in formatText" :key="index" class="text generated-link"
          >{{ textObject.text }}
          <a class="generated-link" :href="textObject.link" target="_blank">{{ textObject.link }}</a></span
        >
      </p>

      <div class="open-button">
        <play-icon
          v-if="(mediaFile && mediaFile.type.split('/')[0] !== 'image') || !mediaFile"
          :width="'2em'"
          :height="'2em'"
          class="play"
        />
        <unicon class="like" v-else name="thumbs-up" />{{
          mediaFile && mediaFile.type.split('/')[0] === 'image' ? 'LIKE THIS ONE' : 'PLAY'
        }}
      </div>
    </div>

    <!-- REMOVE FORM -->
    <form v-if="category === 'remove'">
      <select class="remove-select" v-model="articleToRemove">
        <option v-for="article in articles" :key="article._id" :value="article">
          {{ article.name ? article.name : 'noname' }}
        </option>
      </select>
      <div v-if="articleToRemove" class="article-full">
        <h1><span></span>{{ articleToRemove.name }}</h1>
        <img
          v-if="articleToRemove.mediaType.split('/')[0] === 'image'"
          class="article-picture"
          :src="articleToRemove.link"
        />
        <p class="type">
          {{ articleToRemove.mediaType }}
        </p>
        <p class="text">
          {{ articleToRemove.text ? limitWords(articleToRemove.text, 450) : '' }}
          {{ articleToRemove.text && articleToRemove.text.length > 450 ? '...' : '' }}
        </p>
      </div>
      <div v-if="articleToRemove" class="submit" @click.prevent="!loading ? removeArticle() : ''">
        <p v-if="!loading">SMAZAT</p>
        <div v-else class="spin-icon"><unicon name="spinner" /></div>
      </div>
    </form>

    <!-- EDIT FORM -->
    <form v-if="category === 'edit'">
      <select class="remove-select" v-model="articleToEdit">
        <option v-for="article in articles" :key="article._id" :value="article">
          {{ article.name ? article.name : 'noname' }}
        </option>
      </select>
    </form>

    <form v-if="category === 'edit' && articleToEdit">
      <input placeholder="Název" class="name" v-model="articleToEdit.name" />
      <textarea placeholder="Popis" class="text" v-model="articleToEdit.text"></textarea>
      <input placeholder="youtube link" class="name" v-model="articleToEdit.youtubeLink" />
      <input placeholder="spotify link" class="name" v-model="articleToEdit.spotifyLink" />
      <input placeholder="simplecast link" class="name" v-model="articleToEdit.simplecastLink" />
      <div class="submit" @click.prevent="!loading ? editArticle() : ''">
        <p v-if="!loading">UPRAVIT</p>
        <div v-else class="spin-icon"><unicon name="spinner" /></div>
      </div>
    </form>
    <div v-if="category === 'edit' && articleToEdit" class="article-full">
      <h1><span></span>{{ articleToEdit ? articleToEdit.name : '' }}</h1>
      <img v-if="articleToEdit.mediaType.split('/')[0] === 'image'" class="article-picture" :src="articleToEdit.link" />
      <p v-if="articleToEdit.mediaType.split('/')[0] === 'image'" class="picture-likes">
        <span>LIKES: </span>{{ Math.ceil(Math.random() * 1000000) }}
      </p>
      <p class="type">
        {{ articleToEdit ? articleToEdit.mediaType : '/' }}
      </p>
      <p class="generated-link">
        <span v-for="(textObject, index) in formatEditText" :key="index" class="text generated-link"
          >{{ textObject.text }}
          <a class="generated-link" :href="textObject.link" target="_blank">{{ textObject.link }}</a></span
        >
      </p>
    </div>

    <div class="upload-info">
      <p v-if="loading && loaded !== total">
        {{ (loaded / 1000000).toFixed(2) }}Mb / {{ (total / 1000000).toFixed(2) }}Mb
      </p>
      <p v-if="loading && loaded !== total">Nahrávání na ufften server...</p>
      <p v-else-if="loading && loaded === total && loaded !== 0">
        Nahrávání na google cloud, může trvat i několik minut...
      </p>
      <p v-else-if="doneUpload">Hotovo</p>
    </div>

    <!-- LIST OF ARTICLES -->
    <div v-if="category === 'list'" class="list-of-articles">
      <div v-for="article in articles" :key="article._id" class="article-full">
        <h1><span></span>{{ article.name }}</h1>
        <img v-if="article.mediaType.split('/')[0] === 'image'" class="article-picture" :src="article.link" />
        <p v-if="article.mediaType.split('/')[0] === 'image'" class="picture-likes">
          <span>LIKES: </span>{{ Math.ceil(Math.random() * 1000000) }}
        </p>
        <p class="type">
          {{ article.mediaType }}
        </p>
        <p v-if="article.mediaType.split('/')[0] !== 'image' || !mediaFile" class="text">
          {{ article.text ? limitWords(article.text, 450) : '' }}
          {{ article.text && article.text.length > 450 ? '...' : '' }}
        </p>
        <div class="open-button">
          <play-icon v-if="article.mediaType.split('/')[0] !== 'image'" :width="'2em'" :height="'2em'" class="play" />
          <unicon class="like" v-else name="thumbs-up" />{{
            article.mediaType.split('/')[0] === 'image' ? 'LIKE THIS ONE' : 'PLAY'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { limitWords } from '../../utils/text'
import playIcon from '../../svgs/play.vue'
import TextEditor from '../../components/TextEditor.vue'
export default {
  data() {
    return {
      name: '',
      text: '',
      youtubeLink: '',
      spotifyLink: '',
      simplecastLink: '',
      showLinks: false,
      mediaType: '',
      mediaFileURL: '',
      fullText: '',
      loading: false,
      loaded: 0,
      total: 0,
      doneUpload: false,
      isAudioFile: false,
      mediaFile: null,
      audioFotoFile: null,
      category: 'add',
      articles: [],
      articleToRemove: '',
      articleToEdit: '',
      isText: 0,
      textEditorWindow: false,
    }
  },
  async mounted() {
    this.reloadArticles()
  },
  components: {
    playIcon,
    TextEditor,
  },
  computed: {
    formatText() {
      let text = limitWords(this.text, 350)
      if (text.length < this.text.length) {
        text = text + ' ...'
      }
      let textObjectArray = []
      let lastIndex = 0
      for (;;) {
        let index = text.indexOf('https://', lastIndex + text.slice(index).split(' ')[0].length)
        if (index == -1) {
          textObjectArray.push({
            text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0),
            index,
          })
          break
        }
        textObjectArray.push({
          text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0, index),
          link: text.slice(index).split(' ')[0],
        })
        lastIndex = index
      }
      return textObjectArray
    },
    formatEditText() {
      let text = limitWords(this.articleToEdit.text, 350)
      if (text.length < this.articleToEdit.length) {
        text = text + ' ...'
      }
      let textObjectArray = []
      let lastIndex = 0
      for (;;) {
        let index = text.indexOf('https://', lastIndex + text.slice(index).split(' ')[0].length)
        if (index == -1) {
          textObjectArray.push({
            text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0),
            index,
          })
          break
        }
        textObjectArray.push({
          text: text.slice(lastIndex != 0 ? lastIndex + text.slice(lastIndex).split(' ')[0].length : 0, index),
          link: text.slice(index).split(' ')[0],
        })
        lastIndex = index
      }
      return textObjectArray
    },
  },
  methods: {
    saveText(text) {
      this.fullText = text
      this.textEditorWindow = false
    },
    async reloadArticles() {
      try {
        const { data } = await axios.get('/api/articles/')
        this.articles = data
      } catch (e) {
        this.responseError(e)
      }
    },
    limitWords(text, limit) {
      return limitWords(text, limit)
    },
    setCategory(category) {
      this.category = category
    },
    uploadProgress(loaded, total) {
      this.loaded = loaded
      this.total = total
    },
    previewMediaFile(e) {
      this.doneUpload = false
      this.audioFotoFile = null
      this.mediaFile = e.target.files[0]
      this.mediaFileURL = URL.createObjectURL(e.target.files[0])
      console.log(e.target.files[0].type)
      if (e.target.files[0].type.split('/')[0] === 'audio') this.isAudioFile = true
      else {
        this.isAudioFile = false
      }
    },
    responseError(error) {
      if (!error.response) {
        console.log('no answer')
      } else if (error.response.status === 500) {
        alert('Chyba serveru, zkuste to prosím později')
      } else if (error.response.status === 403) {
        alert('Přihlašte se')
        this.$router.push({ name: 'Login' })
      } else if (error.response.status === 404) {
        alert('Příspěvek nenalezen')
      }
    },
    previewAudioFoto(e) {
      if (!this.mediaFile) return
      if (e.target.files[0].type.split('/')[0] !== 'image') {
        this.$refs.audioPicture.value = null
        this.audioFotoFile = null
        return alert('Audio foto podporuje jenom jpg, png, gif')
      }
      this.audioFotoFile = e.target.files[0]
    },
    async removeArticle() {
      this.loading = true
      try {
        await axios.delete(`/adminapi/article/${this.articleToRemove._id}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        })
      } catch (e) {
        this.responseError(e)
      }

      this.loading = false
      this.reloadArticles()
      this.articleToRemove = null
      this.doneUpload = true
      setTimeout(() => {
        this.doneUpload = false
      }, 5000)
    },
    async editArticle() {
      this.loading = true
      try {
        await axios.post(
          `/adminapi/editarticle/${this.articleToEdit._id}`,
          {
            name: this.articleToEdit.name,
            text: this.articleToEdit.text,
            youtubeLink: this.articleToEdit.youtubeLink,
            spotifyLink: this.articleToEdit.spotifyLink,
            simplecastLink: this.articleToEdit.simplecastLink,
          },
          {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
          }
        )
      } catch (e) {
        this.responseError(e)
      }

      this.loading = false
      this.reloadArticles()
      this.doneUpload = true
      setTimeout(() => {
        this.doneUpload = false
      }, 5000)
    },
    async uploadArticle() {
      if (!this.mediaFile && !this.isText) return alert('přiložte soubor')

      this.loading = true
      this.data = new FormData()

      if (!this.isText) this.data.append('file', this.mediaFile)
      else this.data.append('fullText', this.fullText)

      if (this.isAudioFile && this.audioFotoFile) this.data.append('file', this.audioFotoFile)

      this.data.append('name', this.name)
      this.data.append('text', this.text)
      this.data.append('youtubeLink', this.youtubeLink)
      this.data.append('spotifyLink', this.spotifyLink)
      this.data.append('simplecastLink', this.simplecastLink)

      try {
        const response = await axios.post('/adminapi/article', this.data, {
          onUploadProgress: event => this.uploadProgress(event.loaded, event.total),
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        })
        console.log(response)
      } catch (e) {
        this.responseError(e)
      }
      this.data = null
      this.loading = false
      this.mediaFile = null
      this.$refs.mediaFile.value = null
      this.loaded = 0
      this.total = 0
      this.name = ''
      this.text = ''
      this.doneUpload = true
      setTimeout(() => {
        this.doneUpload = false
      }, 5000)
      this.reloadArticles()
    },
  },
}
</script>

<style lang="scss">
.admin-page {
  height: 90vh;
  width: 100vw;
  margin-top: 4em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .text-editor {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    .text-editor-window {
      background-color: white;
      padding: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0 0 2em 0.2em rgba(0, 0, 0, 0.226);
    }
    .text-file {
      width: 65vw;
      height: 70vh;
      font-family: 'Roboto', sans-serif;
      font-size: 1.1em;
      resize: none;
      outline-color: black;
      padding: 1em;
    }
    button {
      display: block;
      margin: 1em;
      padding: 0.5em;
      outline-color: black;
    }
  }
  .article-full {
    pointer-events: initial;
    opacity: 1 !important;
    position: initial;
    overflow: hidden;
  }
  .list-of-articles {
    padding-top: 10vh;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: 90vh;
    align-items: center;
    justify-content: center;
  }
  .admin-menu {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    .menu-item {
      margin: 1em;
      font-family: 'Roboto', sans-serif;
      font-size: 1.2em;
      &.selected,
      &:hover {
        text-decoration: underline;
        text-underline-offset: 0.2em;
      }
      &.selected {
        font-weight: 600;
      }
    }
  }
  .upload-info {
    font-family: 'Roboto', sans-serif;
    text-decoration: underline;
    text-underline-offset: 0.2em;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    border-top: 1px solid black;
    background-color: white;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 20;
  }
  .text-button {
    font-size: 1em;
    button {
      font-size: 1em;
      padding: 0.5em 1em;
      background-color: white;
      outline: none;
      border: 1px solid black;
      &:hover {
        background-color: black;
        color: white;
      }
    }
  }
  //background-color: red;
  form {
    margin: 0em 2em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    padding: 3em 5em;
    max-height: 75vh;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 20;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.062);
    border: 1px solid black;
    .files {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input {
        font-size: 0.9em;
      }
      .links {
        width: 100%;
        font-size: 0.9em;
      }
    }
    .article-full {
      max-height: 20em;
      min-height: 20em;
    }

    .remove-select {
      font-size: 1.1em;
      margin: 1em;
      min-width: 10em;
    }

    .media-file {
      text-align: center;
      font-size: 1.1em;
      text-decoration: underline;
      font-weight: 600;
      margin: 0.3em;
      //border: 1px solid black;
      padding: 0.5em;
      width: 100%;
      label {
        font-size: 0.8em;
      }
    }
    .upload-progress {
      margin: 1em;
      position: relative;
      width: 10em;
      height: 1em;
      border: 1em;
      .inner-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 1em;
        background-color: black;
      }
    }
    textarea,
    input {
      display: block;
      margin: 0.3em;
      font-size: 1.2em;
      resize: none;
      outline: none;
      border: 1px solid black;
      padding: 0.3em;
      width: 100%;
    }
    textarea {
      min-height: 6em;
      font-family: 'Roboto', sans-serif;
      //margin-bottom: 2em;
    }
    .file {
      max-width: 15em;
      margin-bottom: 0.5em;
      font-size: 0.7em;
      border: none;
    }
    .submit {
      padding: 0 2em;
      border: 1px solid black;
      min-width: 4em;
      min-height: 3.9em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2em;
      width: 80%;
      &:hover {
        background-color: black;
        color: white;
        .spin-icon {
          fill: white;
        }
      }
    }
    .spin-icon {
      height: 1.5em;
      width: fit-content;
      animation: 1s infinite spin;
    }
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
