<template>
  <div class="text-editor-window">
    <textarea ref="textarea" @focus="e => textFocus(e)" v-model="fullText" class="text-file"></textarea>
    <div class="text-button">
      <button @click.prevent="saveText()">Hotovo</button>
    </div>
    <!-- <div style="white-space: pre-wrap; position: absolute; width: 20%; height: 50%" class="text-test">
          {{ fullText }}
        </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullText: '',
    }
  },
  emits: ['save-text'],
  props: ['text'],
  created() {
    this.fullText = this.text
  },
  methods: {
    textFocus(e) {
      e.target.addEventListener('keydown', event => {
        if (event.key === 'Tab') {
          event.preventDefault()
          const start = e.target.selectionStart
          const end = e.target.selectionEnd

          // set textarea value to: text before caret + tab + text after caret
          this.fullText = this.fullText.substring(0, start) + '\t' + this.fullText.substring(end)

          // put caret at right position again
          this.selectionStart = this.selectionEnd = start + 1
        }
      })
    },
    saveText() {
      this.$emit('save-text', this.fullText)
    },
  },
}
</script>

<style lang="scss">
.text-editor-window {
  .text-button {
    button {
      min-width: 100%;
      margin: 0;
      margin-top: 1em;
    }
  }
}
</style>
