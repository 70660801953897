<template>
  <svg viewBox="30 180 300 655" :height="height" :width="width" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 192l512 320L0 832V192z" />
  </svg>
</template>

<script>
export default {
  name: 'play-icon',
  props: ['width', 'height'],
}
</script>

<style></style>
